import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./products`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/discounts`} component={lazy(() => import(`./discounts`))} />
        <Route path={`${APP_PREFIX_PATH}/banners`} component={lazy(() => import(`./banners`))} />
        <Route path={`${APP_PREFIX_PATH}/recipes`} component={lazy(() => import(`./recipes`))} />
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/orders`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
