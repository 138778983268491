// Tables
export const FIRESTORE_ORDERS_TABLE = "orders";
export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_PROMOTIONS_TABLE = "promotions";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_USER_FEEDBACK_TABLE = "userFeedback";
export const FIRESTORE_INVITED_USERS_TABLE = "invitedUsers";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";
export const FIRESTORE_FEATURED_PRODUCTS_TABLE = "featuredProducts";
export const FIRESTORE_BANNERS_TABLE = "banners";
export const FIRESTORE_RECIPES_TABLE = "recipes";
export const FIRESTORE_CATEGORIES_TABLE = "categories";
export const FIRESTORE_LOYALTY_USERS_TABLE = "loyaltyUsers";
export const FIRESTORE_LOYALTY_CLAIMS_TABLE = "loyaltyClaims";
export const FIRESTORE_LOYALTY_REWARDS_TABLE = "loyaltyRewards";

// Variables
export const FIRESTORE_PRODUCT_VARIATIONS_PRICE = "price";
export const FIRESTORE_DEFAULT_ORDERS_MAX_DAYS = 30;
